import { renderSkeleton } from 'landing-page-quotas-request/utils/renderSkeleton/renderSkeleton';

// @ts-ignore
// eslint-disable-next-line no-restricted-globals
if (location.pathname.includes('landing-page')) {
  document.body.style.backgroundColor = 'transparent';

  renderSkeleton();

  import('./landing-page-quotas-request').then(({ init }) => {
    const POSTHOG_SETTINGS = {
      apiKey: process.env.REACT_APP_POSTHOG_PROJECT_KEY,
      apiHost: process.env.REACT_APP_POSTHOG_HOST,
    };

    init({ POSTHOG_SETTINGS });
  });
} else {
  import('./app-wrapper').then(({ init }) => {
    const POSTHOG_SETTINGS = {
      apiKey: process.env.REACT_APP_POSTHOG_PROJECT_KEY,
      apiHost: process.env.REACT_APP_POSTHOG_HOST,
    };

    init({ POSTHOG_SETTINGS });
  });
}
