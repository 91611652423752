const renderColumn = (target: HTMLDivElement, cellClassName?: string, skeletonClassName?: string) => {
  const cell = document.createElement('div');
  cell.classList.add('landing-page-cell');
  if (cellClassName) {
    cell.classList.add(cellClassName || '');
  }
  const skeletonNode = document.createElement('div');
  if (skeletonClassName) {
    skeletonNode.className = skeletonClassName || '';
  }
  cell.appendChild(skeletonNode);
  target.appendChild(cell);
};

export const renderSkeleton = () => {
  const style = document.createElement('style');
  style.innerHTML = `
  @keyframes landing-page-skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  .landing-page-skeleton {
    background: linear-gradient(90deg, rgba(190, 190, 190, 0.05) 25%, 
                                        rgba(129, 129, 129, 0.06) 37%, 
                                        rgba(190, 190, 190, 0.05) 63%);
    background-size: 400% 100%;
    animation: landing-page-skeleton-loading 1.4s ease infinite;
    border-radius: 4px;
  }

  .landing-page-content {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 0px 24px;
  }

  .landing-page-searchBar {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    height: 108px;
    padding: 20px 24px 24px 24px;
    box-shadow: rgba(32, 44, 59, 0.05) 0px 6px 8px 0px;
    border: 1px solid rgba(234, 238, 248, 1);
    background: white;
    border-radius: 8px;
  }

  .landing-page-row_header {
    box-sizing: border-box;
    display: flex;
    gap: 4px;
    width: 100%;
    height: 24px;
    align-items: left;
    padding: 4px 0;
  }

  .landing-page-row_header .landing-page-cell:first-child {
    margin-right: 4px;
  }

  .landing-page-row_header .landing-page-cell:last-child {
    margin-left: 4px;
  }

  .landing-page-row {
    display: flex;
    gap: 4px;
    width: 100%;
    height: 40px;
    align-items: left;
  }

  .landing-page-row .landing-page-cell:first-child {
    margin-right: 4px;
  }

  .landing-page-row .landing-page-cell:last-child {
    margin-left: 4px;
  }

  .landing-page-cell {
    display: flex;
    align-items: center;
    justify-content: left;
    # height: 48px;
    flex-grow: 1;
  }

  .landing-page-skeleton-box {
    height: 100%;
    border-radius: 8px;
  }

  .landing-page-fixed {
    width: 40px;
    flex-grow: 0;
  }

  .landing-page-medium {
    width: 72px;
    flex-grow: 0;
  }

  .landing-page-large {
    flex-grow: 1;
    width: 100%;
  }
  `;
  document.head.appendChild(style);

  document.body.style.margin = '0';

  const root = document.getElementById('root');

  const content = document.createElement('div');
  content.classList.add('landing-page-content');

  const searchBar = document.createElement('div');
  searchBar.classList.add('landing-page-searchBar');

  const firstRow = document.createElement('div');
  firstRow.classList.add('landing-page-row_header');

  renderColumn(firstRow, 'landing-page-fixed');

  Array.from({ length: 4 }).forEach(() => {
    renderColumn(firstRow, '', 'landing-page-skeleton landing-page-skeleton-box landing-page-medium');
  });

  renderColumn(firstRow, 'landing-page-fixed');

  const secondRow = document.createElement('div');
  secondRow.classList.add('landing-page-row');

  renderColumn(secondRow, 'landing-page-fixed', 'landing-page-skeleton landing-page-skeleton-box landing-page-fixed');

  Array.from({ length: 4 }).forEach(() => {
    renderColumn(secondRow, '', 'landing-page-skeleton landing-page-skeleton-box landing-page-large');
  });

  renderColumn(secondRow, 'landing-page-fixed', 'landing-page-skeleton landing-page-skeleton-box landing-page-fixed');

  searchBar.appendChild(firstRow);
  searchBar.appendChild(secondRow);
  content.appendChild(searchBar);
  root?.appendChild(content);
};
